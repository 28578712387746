
import { Component, Vue } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import { MyPerformanceAppraisalsQuery, MissionPerformanceAppraisal } from "@/gql"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import { ApolloError } from "@apollo/client/core"
import { SmartQuery } from "vue-apollo/types/vue-apollo"
import { ApolloQueryResult, NetworkStatus } from "@apollo/client/core"

@Component({
  components: {
    FeedBackMessage,
    AppPage,
  },
})
export default class Appraisals extends Vue {
  readonly myPerformanceAppraisalsQuery = MyPerformanceAppraisalsQuery
  paginate = { page: 1, per: 30 }
  loading = false

  showReviewDialog = false
  activeAppraisal: MissionPerformanceAppraisal | null = null

  pendingCount = 0
  totalCount = 0
  loadingError = false
  networkError = false

  get performanceAppraisalsQueryVars() {
    return {
      filter: {
        state: "PENDING",
      },
      sort: {},
      page: 1,
      per: 6,
    }
  }

  readonly headers = [
    {
      text: "Appraisee",
      value: "appraisee.name",
      sortable: false,
    },
    {
      text: "Appraisee's Role",
      value: "appraiseeRole.name",
      sortable: false,
    },
    {
      text: "Appraiser",
      value: "appraiser.name",
      sortable: true,
    },
    {
      text: "Appraiser's Role",
      value: "appraiserRole.name",
      sortable: false,
    },
    {
      text: "Mission",
      value: "missionLifecycle.name",
      sortable: false,
    },
    {
      text: "Week Ending At",
      value: "createdAt",
      sortable: true,
      custom: true,
    },
    {
      text: "Status",
      value: "surveyAssignment.state",
      sortable: false,
      custom: true,
    },
  ]

  onAppraisalAction(action: string, appraisal: MissionPerformanceAppraisal) {
    this.activeAppraisal = appraisal

    if (action === "review" || action === "open") {
      this.$router.push({
        name: this.$routes.SurveyDetail,
        params: { id: appraisal.surveyAssignment.id.toString() },
      })
    }
  }

  onResult(result: ApolloQueryResult<any>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.loading = false
      this.loadingError = false

      if (result.data && result.data.myPerformanceAppraisals) {
        this.pendingCount = result.data.myPerformanceAppraisals.data.length
        this.totalCount = result.data.myPerformanceAppraisals.pagination.totalSize
      }
    } else if (result.networkStatus === NetworkStatus.error) {
      this.loadingError = true
    }
  }

  refetchQueries() {
    return [
      {
        query: this.myPerformanceAppraisalsQuery,
        variables: { ...this.performanceAppraisalsQueryVars },
      },
    ]
  }
  onError(error: ApolloError) {
    if (error.graphQLErrors) {
      this.loadingError = true
    } else if (error.networkError) {
      this.networkError = true
    }
  }

  async handleLoadingErrorButtonClick(query: SmartQuery<any>) {
    this.$set(this, "loading", true)
    await query.refetch()
  }
}
